<template>
  <!-- 门店订单发货 -->
  <div class="wrapper">
    <!-- <admin-title title="物流配送 - 门店自提"></admin-title> -->
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="手机串号" prop="serialNo" :rules="rules">
        <el-input style="width:500px;" v-model="formData.serialNo" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="监管锁" prop="lockCode">
        <el-input style="width:500px;" v-model="formData.lockCode" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="签收证明" prop="receiveImageList" :rules="rules">
        <!-- <FileUpload :isMulti="true" :name.sync="formData.deliveryImage" :url="formData.masterImageUrlList"></FileUpload> -->
        <FileUpload :isMulti="true" :name.sync="formData.receiveImageList"></FileUpload>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" style="width:500px;" v-model="formData.remark" size="small" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="">
        <el-button type="" size="small" @click="closePage">取消</el-button>
        <el-button type="primary" size="small" @click="save">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { handleDelieryStoreAPI } from './api'
import FileUpload from '@/components/file-upload/index-send.vue'

export default {
  name: 'DeliveryStore',
  components: { FileUpload },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        orderNo: this.$route.params.id,
        serialNo: '',
        lockCode: '',
        receiveImageList: [],
        remark: ''
      }
    }
  },

  mounted() {},

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          handleDelieryStoreAPI(this.formData).then(() => {
            this.$message.success('自提成功')
            //
            setTimeout(() => {
              this.closePage()
            }, 1000)
          })
        }
      })
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        window.opener.getBpageList()
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList()
      }
    },
    closePage() {
      this.emitAwindow()
      window.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }

  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }

  .goods-sku,
  .baseInfo-2 {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }

    .avatar {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
}
</style>
